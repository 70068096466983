html {
  min-height: 100%;
  position: relative; }

body {
  margin-bottom: 60px; }

.logo-banner {
  margin: 0 0 55px;
  max-height: 100px; }

.site-logo img {
  max-height: 100px;
  margin-left: auto;
  margin-right: auto; }

.admin-site-logo img {
  max-height: 50px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto; }

footer {
  background-color: #232323;
  bottom: 0;
  color: #232323;
  float: right;
  font-size: .85em;
  height: 60px;
  position: absolute;
  width: 100%; }
  footer p {
    padding: 15px; }

.heading {
  font-family: "PT Sans Narrow",sans-serif;
  margin: 0 0 10px;
  text-transform: uppercase; }

.pre-footer {
  background: #313030;
  color: #b0b0b0;
  margin-top: 25px;
  padding: 25px 0; }
  .pre-footer a {
    color: #57c8eb; }

.img-wrapper {
  display: table-cell;
  height: 230px;
  margin: 0 auto;
  vertical-align: bottom;
  width: 50%;
  max-width: 300px; }

.box-large img {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%; }

.box {
  border: 1px solid #eee;
  box-shadow: 5px 5px rgba(234, 234, 234, 0.9);
  height: 330px;
  margin-bottom: 25px; }
  .box a {
    text-decoration: none; }
  .box img {
    margin-left: auto;
    margin-right: auto;
    max-height: 200px;
    max-width: 90%; }
  .box h4,
  .box h5 {
    margin-top: 10px;
    text-align: center;
    text-transform: uppercase; }
  .box h4 {
    bottom: 10%;
    left: 33%;
    position: absolute; }
  .box h5 {
    color: #888;
    padding: 0 10px; }

.social-icon {
  padding-top: 8px;
  font-size: 32px;
  text-align: center;
  width: 48px;
  height: 48px;
  border: 1px solid #777;
  border-radius: 50%;
  color: #777;
  margin: 5px; }

a.social-icon:hover, a.social-icon:active, a.social-icon:focus {
  text-decoration: none;
  color: #FFF;
  border-color: #FFF; }

.banner-h {
  max-height: 100px; }

/*# sourceMappingURL=app.css.map */
